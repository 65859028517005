/**
 * @file Configuration of the Redux store
 */

import type { StoreEnhancer } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import metricsFilterSlice from "./metricsFilterSlice";
import activeAssetSlice from "./activeAssetSlice";
import newAssetSlice from "./newAssetSlice";
import insertionDateSlice from "./insertionDateSlice";
import mapSlice from "./mapSlice";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer() as StoreEnhancer;

const store = configureStore({
  reducer: {
    activeAsset: activeAssetSlice,
    newAsset: newAssetSlice,
    insertionDate: insertionDateSlice,
    map: mapSlice,
    metricsFilter: metricsFilterSlice,
  },
  enhancers: [sentryReduxEnhancer],
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
