import type { PayloadAction} from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

/** stores the last-used date for inspection/properties insertion */
export const insertionDateSlice = createSlice({
  name: "insertionDate",
  initialState: {
    date: dayjs().format("YYYY-MM-DD"),
  },
  reducers: {
    setDate: (state, { payload }: PayloadAction<string>) => {
      state.date = payload;
    },
  },
});

export default insertionDateSlice.reducer;
export const { setDate } = insertionDateSlice.actions;
