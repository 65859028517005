/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n                  fragment NewCapBeamInspection on CapBeamInspection {\n                    id\n                    inspectionDate\n                    steelCondition\n                    decays {\n                      width\n                      depth\n                      length\n                      span\n                      location\n                    }\n                    inspectedBy {\n                      firstName\n                      lastName\n                    }\n                  }\n                ": types.NewCapBeamInspectionFragmentDoc,
    "\n                  fragment NewPileInspection on PileInspection {\n                    id\n                    inspectionDate\n                    wastedDiameter\n                    seabedDiameter\n                    condition\n                    inspectedBy {\n                      firstName\n                      lastName\n                    }\n                  }\n                ": types.NewPileInspectionFragmentDoc,
    "\n  mutation DeletePileInspection($id: ID!) {\n    deletePileInspection(id: $id)\n  }\n": types.DeletePileInspectionDocument,
    "\n  mutation UpdateOpenSpaceLink(\n    $assetType: AssetType!\n    $assetId: ID!\n    $link: String\n  ) {\n    updateOpenspaceLink(assetType: $assetType, assetId: $assetId, link: $link)\n  }\n": types.UpdateOpenSpaceLinkDocument,
    "\n      mutation DeleteCapBeamProperty($id: ID!) {\n        deleteCapBeamProperty(id: $id)\n      }\n    ": types.DeleteCapBeamPropertyDocument,
    "\n      mutation CreateCapBeamProperty($input: CapBeamPropertyInput!) {\n        createCapBeamProperty(input: $input) {\n          id\n        }\n      }\n    ": types.CreateCapBeamPropertyDocument,
    "\n  mutation CreatePileProperty($input: PilePropertyInput!) {\n    createPileProperty(input: $input) {\n      id\n    }\n  }\n": types.CreatePilePropertyDocument,
    "\n      mutation DeletePileProperty($id: ID!) {\n        deletePileProperty(id: $id)\n      }\n    ": types.DeletePilePropertyDocument,
    "\n  query GetPortGeographic {\n    port {\n      center {\n        latitude\n        longitude\n      }\n      zoomLevel\n      id\n      mapTmsUrl\n    }\n  }\n": types.GetPortGeographicDocument,
    "\n  query getWharvesMultiSelect {\n    port {\n      id\n      wharves {\n        id\n        name\n      }\n    }\n  }\n": types.GetWharvesMultiSelectDocument,
    "\n  query GetPort {\n    port {\n      name\n      id\n    }\n  }\n": types.GetPortDocument,
    "\n  mutation DeleteCapBeamInspection($id: ID!) {\n    deleteCapBeamInspection(id: $id)\n  }\n": types.DeleteCapBeamInspectionDocument,
    "\n  query GetCapBeamInspections($id: ID!) {\n    capBeam(id: $id) {\n      id\n      inspections {\n        inspectionDate\n        id\n        steelCondition\n        decays {\n          depth\n          width\n          length\n          span\n          id\n          location\n        }\n        inspectedBy {\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n": types.GetCapBeamInspectionsDocument,
    "\n  mutation CreateCapBeamInspection($input: CapBeamInspectionInput!) {\n    createCapBeamInspection(input: $input) {\n      id\n      inspectionDate\n      steelCondition\n      decays {\n        width\n        depth\n        length\n        span\n        location\n      }\n      inspectedBy {\n        firstName\n        lastName\n      }\n    }\n  }\n": types.CreateCapBeamInspectionDocument,
    "\n  mutation CreatePileInspection($input: PileInspectionInput!) {\n    createPileInspection(input: $input) {\n      id\n      inspectionDate\n      wastedDiameter\n      seabedDiameter\n      condition\n      jacketCondition\n      inspectedBy {\n        firstName\n        lastName\n      }\n    }\n  }\n": types.CreatePileInspectionDocument,
    "\n  query GetPileInspections($id: ID!) {\n    pile(id: $id) {\n      id\n      inspections {\n        inspectionDate\n        id\n        condition\n        jacketCondition\n        seabedDiameter\n        wastedDiameter\n        inspectedBy {\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n": types.GetPileInspectionsDocument,
    "\n  query GetCapBeamProperties($id: ID!) {\n    capBeam(id: $id) {\n      id\n      properties {\n        id\n        appointedOn\n        breadth\n        depth\n      }\n    }\n  }\n": types.GetCapBeamPropertiesDocument,
    "\n  query GetPileProperties($id: ID!) {\n    pile(id: $id) {\n      id\n      properties {\n        id\n        length\n        headDiameter\n        appointedOn\n        embedmentDepth\n      }\n    }\n  }\n": types.GetPilePropertiesDocument,
    "\n  query GetPileTimeline($id: ID!) {\n    pile(id: $id) {\n      id\n      inspections {\n        id\n        inspectionDate\n      }\n      properties {\n        id\n        appointedOn\n      }\n      timeline {\n        capacityUtilization\n        condition\n        eventId\n        eventType\n      }\n    }\n  }\n": types.GetPileTimelineDocument,
    "\n  query GetCapBeamTimeline($id: ID!) {\n    capBeam(id: $id) {\n      id\n      inspections {\n        id\n        inspectionDate\n      }\n      properties {\n        id\n        appointedOn\n      }\n      timeline {\n        capacityUtilization\n        condition\n        eventId\n        eventType\n      }\n    }\n  }\n": types.GetCapBeamTimelineDocument,
    "\n  query GetCapBeamsTable {\n    capBeams {\n      edges {\n        node {\n          id\n          condition\n          name\n          wharf {\n            name\n          }\n          bent\n          rowSpan\n          capacityUtilization\n          inspectionRequired\n          inspections {\n            inspectionDate\n            inspectedBy {\n              firstName\n              lastName\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GetCapBeamsTableDocument,
    "\n  query GetPilesTable {\n    piles {\n      edges {\n        node {\n          id\n          condition\n          name\n          wharf {\n            name\n          }\n          bent\n          row\n          broken\n          side\n          capacityUtilization\n          inspectionRequired\n          inspections {\n            wastedDiameter\n            seabedDiameter\n            inspectionDate\n            inspectedBy {\n              firstName\n              lastName\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GetPilesTableDocument,
    "\n  query GetInspectionFrequencies {\n    inspectionFrequencies {\n      id\n      period\n      wharf {\n        name\n      }\n      assetType\n    }\n  }\n": types.GetInspectionFrequenciesDocument,
    "\n  query GetCapBeamsGeographic {\n    capBeams {\n      edges {\n        node {\n          coordinates {\n            latitude\n            longitude\n          }\n          id\n          condition\n          inCommission\n          name\n          openspaceLink\n          inspectionRequired\n        }\n      }\n    }\n  }\n": types.GetCapBeamsGeographicDocument,
    "\n  query GetPilesGeographic {\n    piles {\n      edges {\n        node {\n          id\n          coordinates {\n            latitude\n            longitude\n          }\n          condition\n          inCommission\n          name\n          openspaceLink\n          inspectionRequired\n        }\n      }\n    }\n  }\n": types.GetPilesGeographicDocument,
    "\n  query GetNotes($assetId: ID!, $assetType: AssetType!) {\n    notes(assetId: $assetId, assetType: $assetType) {\n      id\n      postedBy {\n        firstName\n        lastName\n      }\n      postedDate\n      images\n      context\n    }\n  }\n": types.GetNotesDocument,
    "\n  mutation DeleteAsset($assetType: AssetType!, $assetId: ID!) {\n    deleteAsset(assetType: $assetType, assetId: $assetId)\n  }\n": types.DeleteAssetDocument,
    "\n  query GetPilesGeoEdit {\n    piles {\n      edges {\n        node {\n          id\n          coordinates {\n            latitude\n            longitude\n          }\n          inCommission\n          name\n        }\n      }\n    }\n  }\n": types.GetPilesGeoEditDocument,
    "\n  query GetCapBeamsGeoEdit {\n    capBeams {\n      edges {\n        node {\n          coordinates {\n            latitude\n            longitude\n          }\n          id\n          inCommission\n          name\n        }\n      }\n    }\n  }\n": types.GetCapBeamsGeoEditDocument,
    "\n  query GetCapBeamDefaultProperties {\n    capBeamDefaultProperties {\n      id\n      wharf\n      breadth\n      depth\n    }\n  }\n": types.GetCapBeamDefaultPropertiesDocument,
    "\n  query GetPileDefaultProperties {\n    pileDefaultProperties {\n      id\n      length\n      row\n      wharf\n      headDiameter\n      embedmentDepth\n    }\n  }\n": types.GetPileDefaultPropertiesDocument,
    "\n      query GetAsset($type: AssetType!, $id: ID!) {\n        asset(assetType: $type, assetId: $id) {\n          id\n          name\n          openspaceLink\n          coordinates {\n            latitude\n            longitude\n          }\n        }\n      }\n    ": types.GetAssetDocument,
    "\n  query GetPiles {\n    piles {\n      edges {\n        node {\n          row\n          bent\n          side\n          wharf {\n            id\n          }\n        }\n      }\n    }\n  }\n": types.GetPilesDocument,
    "\n  query GetCapBeams {\n    capBeams {\n      edges {\n        node {\n          rowSpan\n          bent\n          side\n          wharf {\n            id\n          }\n        }\n      }\n    }\n  }\n": types.GetCapBeamsDocument,
    "\n      mutation DeleteCapBeamDefaultProperty($id: ID!) {\n        deleteCapBeamDefaultProperty(id: $id)\n      }\n    ": types.DeleteCapBeamDefaultPropertyDocument,
    "\n      mutation NewCapBeamDefaultProperty($input: CapBeamDefaultPropertyInput!) {\n        createCapBeamDefaultProperty(input: $input) {\n          id\n        }\n      }\n    ": types.NewCapBeamDefaultPropertyDocument,
    "\n      mutation NewPileDefaultProperty($input: PileDefaultPropertyInput!) {\n        createPileDefaultProperty(input: $input) {\n          id\n        }\n      }\n    ": types.NewPileDefaultPropertyDocument,
    "\n      mutation DeletePileDefaultProperty($id: ID!) {\n        deletePileDefaultProperty(id: $id)\n      }\n    ": types.DeletePileDefaultPropertyDocument,
    "\n  mutation CreateInspectionFrequency($input: InspectionFrequencyInput!) {\n    createInspectionFrequency(input: $input) {\n      id\n    }\n  }\n": types.CreateInspectionFrequencyDocument,
    "\n      mutation DeleteInspectionFrequency($id: ID!) {\n        deleteInspectionFrequency(id: $id)\n      }\n    ": types.DeleteInspectionFrequencyDocument,
    "\n      mutation CreateCapBeamLoad($input: CapBeamLoadInput!) {\n        createCapBeamLoad(input: $input) {\n          id\n        }\n      }\n    ": types.CreateCapBeamLoadDocument,
    "\n      mutation DeleteCapBeamLoad($id: ID!) {\n        deleteCapBeamLoad(id: $id)\n      }\n    ": types.DeleteCapBeamLoadDocument,
    "\n  query GetCapBeamLoads {\n    capBeamLoads {\n      id\n      bendingLoad\n      shearLoad\n      wharf {\n        name\n      }\n    }\n  }\n": types.GetCapBeamLoadsDocument,
    "\n      mutation CreatePileLoad($input: PileLoadInput!) {\n        createPileLoad(input: $input) {\n          id\n        }\n      }\n    ": types.CreatePileLoadDocument,
    "\n      mutation DeletePileLoad($id: ID!) {\n        deletePileLoad(id: $id)\n      }\n    ": types.DeletePileLoadDocument,
    "\n  query GetPileLoads {\n    pileLoads {\n      id\n      load\n      wharf {\n        name\n      }\n      row\n    }\n  }\n": types.GetPileLoadsDocument,
    "\n  mutation CreateCapBeam($input: CapBeamInput!) {\n    createCapBeam(input: $input) {\n      id\n    }\n  }\n": types.CreateCapBeamDocument,
    "\n  mutation CreatePile($input: PileInput!) {\n    createPile(input: $input) {\n      id\n    }\n  }\n": types.CreatePileDocument,
    "\n      mutation DeleteDocument($id: ID!) {\n        deleteDocument(id: $id)\n      }\n    ": types.DeleteDocumentDocument,
    "\n  query GetDocuments($id: ID!, $assetType: AssetType!) {\n    documents(assetId: $id, assetType: $assetType) {\n      id\n      uploadedBy {\n        firstName\n        lastName\n      }\n      uploadedDate\n      fileUrl\n      context\n      name\n    }\n  }\n": types.GetDocumentsDocument,
    "\n      mutation CreateDocument($input: DocumentInput!) {\n        createDocument(input: $input) {\n          id\n          fileUrl\n        }\n      }\n    ": types.CreateDocumentDocument,
    "\n      mutation CreateNote($input: NoteInput!) {\n        createNote(input: $input) {\n          id\n          images\n        }\n      }\n    ": types.CreateNoteDocument,
    "\n  mutation DeleteNote($id: ID!) {\n    deleteNote(id: $id)\n  }\n": types.DeleteNoteDocument,
    "\n      query GetPile($id: ID!) {\n        pile(id: $id) {\n          condition\n          inspectionRequired\n        }\n      }\n    ": types.GetPileDocument,
    "\n        fragment PileChanged on Pile {\n          condition\n          inspectionRequired\n        }\n      ": types.PileChangedFragmentDoc,
    "\n      query getCapBeam($id: ID!) {\n        capBeam(id: $id) {\n          condition\n          inspectionRequired\n        }\n      }\n    ": types.GetCapBeamDocument,
    "\n        fragment CapBeamChanged on CapBeam {\n          condition\n          inspectionRequired\n        }\n      ": types.CapBeamChangedFragmentDoc,
    "\n  mutation InviteUser($email: String!, $role: String!) {\n    inviteUser(email: $email, role: $role)\n  }\n": types.InviteUserDocument,
    "\n  mutation UpdateUserRole($userId: ID!, $role: String!) {\n    updateUserRole(userId: $userId, role: $role) {\n      id\n    }\n  }\n": types.UpdateUserRoleDocument,
    "\n      query GetUsers {\n        port {\n          users {\n            email\n            firstName\n            lastName\n            id\n            role\n          }\n        }\n      }\n    ": types.GetUsersDocument,
    "\n      query GetAverageConditionMetrics(\n        $wharves: [String!]\n        $assetTypes: [AssetType!]\n      ) {\n        averageConditionMetric(wharves: $wharves, assetTypes: $assetTypes) {\n          date\n          condition\n        }\n      }\n    ": types.GetAverageConditionMetricsDocument,
    "\n      query GetOverviewMetric($wharves: [String!], $assetTypes: [AssetType!]) {\n        overviewMetric(wharves: $wharves, assetTypes: $assetTypes) {\n          label\n          count\n        }\n      }\n    ": types.GetOverviewMetricDocument,
    "\n      query GetRequiredInspectionsMetric(\n        $wharves: [String!]\n        $assetTypes: [AssetType!]\n      ) {\n        requiredInspectionsMetric(wharves: $wharves, assetTypes: $assetTypes) {\n          required\n          notRequired\n        }\n      }\n    ": types.GetRequiredInspectionsMetricDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n                  fragment NewCapBeamInspection on CapBeamInspection {\n                    id\n                    inspectionDate\n                    steelCondition\n                    decays {\n                      width\n                      depth\n                      length\n                      span\n                      location\n                    }\n                    inspectedBy {\n                      firstName\n                      lastName\n                    }\n                  }\n                "): (typeof documents)["\n                  fragment NewCapBeamInspection on CapBeamInspection {\n                    id\n                    inspectionDate\n                    steelCondition\n                    decays {\n                      width\n                      depth\n                      length\n                      span\n                      location\n                    }\n                    inspectedBy {\n                      firstName\n                      lastName\n                    }\n                  }\n                "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n                  fragment NewPileInspection on PileInspection {\n                    id\n                    inspectionDate\n                    wastedDiameter\n                    seabedDiameter\n                    condition\n                    inspectedBy {\n                      firstName\n                      lastName\n                    }\n                  }\n                "): (typeof documents)["\n                  fragment NewPileInspection on PileInspection {\n                    id\n                    inspectionDate\n                    wastedDiameter\n                    seabedDiameter\n                    condition\n                    inspectedBy {\n                      firstName\n                      lastName\n                    }\n                  }\n                "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeletePileInspection($id: ID!) {\n    deletePileInspection(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeletePileInspection($id: ID!) {\n    deletePileInspection(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateOpenSpaceLink(\n    $assetType: AssetType!\n    $assetId: ID!\n    $link: String\n  ) {\n    updateOpenspaceLink(assetType: $assetType, assetId: $assetId, link: $link)\n  }\n"): (typeof documents)["\n  mutation UpdateOpenSpaceLink(\n    $assetType: AssetType!\n    $assetId: ID!\n    $link: String\n  ) {\n    updateOpenspaceLink(assetType: $assetType, assetId: $assetId, link: $link)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation DeleteCapBeamProperty($id: ID!) {\n        deleteCapBeamProperty(id: $id)\n      }\n    "): (typeof documents)["\n      mutation DeleteCapBeamProperty($id: ID!) {\n        deleteCapBeamProperty(id: $id)\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation CreateCapBeamProperty($input: CapBeamPropertyInput!) {\n        createCapBeamProperty(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateCapBeamProperty($input: CapBeamPropertyInput!) {\n        createCapBeamProperty(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreatePileProperty($input: PilePropertyInput!) {\n    createPileProperty(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreatePileProperty($input: PilePropertyInput!) {\n    createPileProperty(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation DeletePileProperty($id: ID!) {\n        deletePileProperty(id: $id)\n      }\n    "): (typeof documents)["\n      mutation DeletePileProperty($id: ID!) {\n        deletePileProperty(id: $id)\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPortGeographic {\n    port {\n      center {\n        latitude\n        longitude\n      }\n      zoomLevel\n      id\n      mapTmsUrl\n    }\n  }\n"): (typeof documents)["\n  query GetPortGeographic {\n    port {\n      center {\n        latitude\n        longitude\n      }\n      zoomLevel\n      id\n      mapTmsUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getWharvesMultiSelect {\n    port {\n      id\n      wharves {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query getWharvesMultiSelect {\n    port {\n      id\n      wharves {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPort {\n    port {\n      name\n      id\n    }\n  }\n"): (typeof documents)["\n  query GetPort {\n    port {\n      name\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteCapBeamInspection($id: ID!) {\n    deleteCapBeamInspection(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteCapBeamInspection($id: ID!) {\n    deleteCapBeamInspection(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCapBeamInspections($id: ID!) {\n    capBeam(id: $id) {\n      id\n      inspections {\n        inspectionDate\n        id\n        steelCondition\n        decays {\n          depth\n          width\n          length\n          span\n          id\n          location\n        }\n        inspectedBy {\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCapBeamInspections($id: ID!) {\n    capBeam(id: $id) {\n      id\n      inspections {\n        inspectionDate\n        id\n        steelCondition\n        decays {\n          depth\n          width\n          length\n          span\n          id\n          location\n        }\n        inspectedBy {\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateCapBeamInspection($input: CapBeamInspectionInput!) {\n    createCapBeamInspection(input: $input) {\n      id\n      inspectionDate\n      steelCondition\n      decays {\n        width\n        depth\n        length\n        span\n        location\n      }\n      inspectedBy {\n        firstName\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateCapBeamInspection($input: CapBeamInspectionInput!) {\n    createCapBeamInspection(input: $input) {\n      id\n      inspectionDate\n      steelCondition\n      decays {\n        width\n        depth\n        length\n        span\n        location\n      }\n      inspectedBy {\n        firstName\n        lastName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreatePileInspection($input: PileInspectionInput!) {\n    createPileInspection(input: $input) {\n      id\n      inspectionDate\n      wastedDiameter\n      seabedDiameter\n      condition\n      jacketCondition\n      inspectedBy {\n        firstName\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreatePileInspection($input: PileInspectionInput!) {\n    createPileInspection(input: $input) {\n      id\n      inspectionDate\n      wastedDiameter\n      seabedDiameter\n      condition\n      jacketCondition\n      inspectedBy {\n        firstName\n        lastName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPileInspections($id: ID!) {\n    pile(id: $id) {\n      id\n      inspections {\n        inspectionDate\n        id\n        condition\n        jacketCondition\n        seabedDiameter\n        wastedDiameter\n        inspectedBy {\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPileInspections($id: ID!) {\n    pile(id: $id) {\n      id\n      inspections {\n        inspectionDate\n        id\n        condition\n        jacketCondition\n        seabedDiameter\n        wastedDiameter\n        inspectedBy {\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCapBeamProperties($id: ID!) {\n    capBeam(id: $id) {\n      id\n      properties {\n        id\n        appointedOn\n        breadth\n        depth\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCapBeamProperties($id: ID!) {\n    capBeam(id: $id) {\n      id\n      properties {\n        id\n        appointedOn\n        breadth\n        depth\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPileProperties($id: ID!) {\n    pile(id: $id) {\n      id\n      properties {\n        id\n        length\n        headDiameter\n        appointedOn\n        embedmentDepth\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPileProperties($id: ID!) {\n    pile(id: $id) {\n      id\n      properties {\n        id\n        length\n        headDiameter\n        appointedOn\n        embedmentDepth\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPileTimeline($id: ID!) {\n    pile(id: $id) {\n      id\n      inspections {\n        id\n        inspectionDate\n      }\n      properties {\n        id\n        appointedOn\n      }\n      timeline {\n        capacityUtilization\n        condition\n        eventId\n        eventType\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPileTimeline($id: ID!) {\n    pile(id: $id) {\n      id\n      inspections {\n        id\n        inspectionDate\n      }\n      properties {\n        id\n        appointedOn\n      }\n      timeline {\n        capacityUtilization\n        condition\n        eventId\n        eventType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCapBeamTimeline($id: ID!) {\n    capBeam(id: $id) {\n      id\n      inspections {\n        id\n        inspectionDate\n      }\n      properties {\n        id\n        appointedOn\n      }\n      timeline {\n        capacityUtilization\n        condition\n        eventId\n        eventType\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCapBeamTimeline($id: ID!) {\n    capBeam(id: $id) {\n      id\n      inspections {\n        id\n        inspectionDate\n      }\n      properties {\n        id\n        appointedOn\n      }\n      timeline {\n        capacityUtilization\n        condition\n        eventId\n        eventType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCapBeamsTable {\n    capBeams {\n      edges {\n        node {\n          id\n          condition\n          name\n          wharf {\n            name\n          }\n          bent\n          rowSpan\n          capacityUtilization\n          inspectionRequired\n          inspections {\n            inspectionDate\n            inspectedBy {\n              firstName\n              lastName\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCapBeamsTable {\n    capBeams {\n      edges {\n        node {\n          id\n          condition\n          name\n          wharf {\n            name\n          }\n          bent\n          rowSpan\n          capacityUtilization\n          inspectionRequired\n          inspections {\n            inspectionDate\n            inspectedBy {\n              firstName\n              lastName\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPilesTable {\n    piles {\n      edges {\n        node {\n          id\n          condition\n          name\n          wharf {\n            name\n          }\n          bent\n          row\n          broken\n          side\n          capacityUtilization\n          inspectionRequired\n          inspections {\n            wastedDiameter\n            seabedDiameter\n            inspectionDate\n            inspectedBy {\n              firstName\n              lastName\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPilesTable {\n    piles {\n      edges {\n        node {\n          id\n          condition\n          name\n          wharf {\n            name\n          }\n          bent\n          row\n          broken\n          side\n          capacityUtilization\n          inspectionRequired\n          inspections {\n            wastedDiameter\n            seabedDiameter\n            inspectionDate\n            inspectedBy {\n              firstName\n              lastName\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetInspectionFrequencies {\n    inspectionFrequencies {\n      id\n      period\n      wharf {\n        name\n      }\n      assetType\n    }\n  }\n"): (typeof documents)["\n  query GetInspectionFrequencies {\n    inspectionFrequencies {\n      id\n      period\n      wharf {\n        name\n      }\n      assetType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCapBeamsGeographic {\n    capBeams {\n      edges {\n        node {\n          coordinates {\n            latitude\n            longitude\n          }\n          id\n          condition\n          inCommission\n          name\n          openspaceLink\n          inspectionRequired\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCapBeamsGeographic {\n    capBeams {\n      edges {\n        node {\n          coordinates {\n            latitude\n            longitude\n          }\n          id\n          condition\n          inCommission\n          name\n          openspaceLink\n          inspectionRequired\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPilesGeographic {\n    piles {\n      edges {\n        node {\n          id\n          coordinates {\n            latitude\n            longitude\n          }\n          condition\n          inCommission\n          name\n          openspaceLink\n          inspectionRequired\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPilesGeographic {\n    piles {\n      edges {\n        node {\n          id\n          coordinates {\n            latitude\n            longitude\n          }\n          condition\n          inCommission\n          name\n          openspaceLink\n          inspectionRequired\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetNotes($assetId: ID!, $assetType: AssetType!) {\n    notes(assetId: $assetId, assetType: $assetType) {\n      id\n      postedBy {\n        firstName\n        lastName\n      }\n      postedDate\n      images\n      context\n    }\n  }\n"): (typeof documents)["\n  query GetNotes($assetId: ID!, $assetType: AssetType!) {\n    notes(assetId: $assetId, assetType: $assetType) {\n      id\n      postedBy {\n        firstName\n        lastName\n      }\n      postedDate\n      images\n      context\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteAsset($assetType: AssetType!, $assetId: ID!) {\n    deleteAsset(assetType: $assetType, assetId: $assetId)\n  }\n"): (typeof documents)["\n  mutation DeleteAsset($assetType: AssetType!, $assetId: ID!) {\n    deleteAsset(assetType: $assetType, assetId: $assetId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPilesGeoEdit {\n    piles {\n      edges {\n        node {\n          id\n          coordinates {\n            latitude\n            longitude\n          }\n          inCommission\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPilesGeoEdit {\n    piles {\n      edges {\n        node {\n          id\n          coordinates {\n            latitude\n            longitude\n          }\n          inCommission\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCapBeamsGeoEdit {\n    capBeams {\n      edges {\n        node {\n          coordinates {\n            latitude\n            longitude\n          }\n          id\n          inCommission\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCapBeamsGeoEdit {\n    capBeams {\n      edges {\n        node {\n          coordinates {\n            latitude\n            longitude\n          }\n          id\n          inCommission\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCapBeamDefaultProperties {\n    capBeamDefaultProperties {\n      id\n      wharf\n      breadth\n      depth\n    }\n  }\n"): (typeof documents)["\n  query GetCapBeamDefaultProperties {\n    capBeamDefaultProperties {\n      id\n      wharf\n      breadth\n      depth\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPileDefaultProperties {\n    pileDefaultProperties {\n      id\n      length\n      row\n      wharf\n      headDiameter\n      embedmentDepth\n    }\n  }\n"): (typeof documents)["\n  query GetPileDefaultProperties {\n    pileDefaultProperties {\n      id\n      length\n      row\n      wharf\n      headDiameter\n      embedmentDepth\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetAsset($type: AssetType!, $id: ID!) {\n        asset(assetType: $type, assetId: $id) {\n          id\n          name\n          openspaceLink\n          coordinates {\n            latitude\n            longitude\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetAsset($type: AssetType!, $id: ID!) {\n        asset(assetType: $type, assetId: $id) {\n          id\n          name\n          openspaceLink\n          coordinates {\n            latitude\n            longitude\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPiles {\n    piles {\n      edges {\n        node {\n          row\n          bent\n          side\n          wharf {\n            id\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPiles {\n    piles {\n      edges {\n        node {\n          row\n          bent\n          side\n          wharf {\n            id\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCapBeams {\n    capBeams {\n      edges {\n        node {\n          rowSpan\n          bent\n          side\n          wharf {\n            id\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCapBeams {\n    capBeams {\n      edges {\n        node {\n          rowSpan\n          bent\n          side\n          wharf {\n            id\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation DeleteCapBeamDefaultProperty($id: ID!) {\n        deleteCapBeamDefaultProperty(id: $id)\n      }\n    "): (typeof documents)["\n      mutation DeleteCapBeamDefaultProperty($id: ID!) {\n        deleteCapBeamDefaultProperty(id: $id)\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation NewCapBeamDefaultProperty($input: CapBeamDefaultPropertyInput!) {\n        createCapBeamDefaultProperty(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation NewCapBeamDefaultProperty($input: CapBeamDefaultPropertyInput!) {\n        createCapBeamDefaultProperty(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation NewPileDefaultProperty($input: PileDefaultPropertyInput!) {\n        createPileDefaultProperty(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation NewPileDefaultProperty($input: PileDefaultPropertyInput!) {\n        createPileDefaultProperty(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation DeletePileDefaultProperty($id: ID!) {\n        deletePileDefaultProperty(id: $id)\n      }\n    "): (typeof documents)["\n      mutation DeletePileDefaultProperty($id: ID!) {\n        deletePileDefaultProperty(id: $id)\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateInspectionFrequency($input: InspectionFrequencyInput!) {\n    createInspectionFrequency(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateInspectionFrequency($input: InspectionFrequencyInput!) {\n    createInspectionFrequency(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation DeleteInspectionFrequency($id: ID!) {\n        deleteInspectionFrequency(id: $id)\n      }\n    "): (typeof documents)["\n      mutation DeleteInspectionFrequency($id: ID!) {\n        deleteInspectionFrequency(id: $id)\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation CreateCapBeamLoad($input: CapBeamLoadInput!) {\n        createCapBeamLoad(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateCapBeamLoad($input: CapBeamLoadInput!) {\n        createCapBeamLoad(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation DeleteCapBeamLoad($id: ID!) {\n        deleteCapBeamLoad(id: $id)\n      }\n    "): (typeof documents)["\n      mutation DeleteCapBeamLoad($id: ID!) {\n        deleteCapBeamLoad(id: $id)\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCapBeamLoads {\n    capBeamLoads {\n      id\n      bendingLoad\n      shearLoad\n      wharf {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCapBeamLoads {\n    capBeamLoads {\n      id\n      bendingLoad\n      shearLoad\n      wharf {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation CreatePileLoad($input: PileLoadInput!) {\n        createPileLoad(input: $input) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreatePileLoad($input: PileLoadInput!) {\n        createPileLoad(input: $input) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation DeletePileLoad($id: ID!) {\n        deletePileLoad(id: $id)\n      }\n    "): (typeof documents)["\n      mutation DeletePileLoad($id: ID!) {\n        deletePileLoad(id: $id)\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPileLoads {\n    pileLoads {\n      id\n      load\n      wharf {\n        name\n      }\n      row\n    }\n  }\n"): (typeof documents)["\n  query GetPileLoads {\n    pileLoads {\n      id\n      load\n      wharf {\n        name\n      }\n      row\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateCapBeam($input: CapBeamInput!) {\n    createCapBeam(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateCapBeam($input: CapBeamInput!) {\n    createCapBeam(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreatePile($input: PileInput!) {\n    createPile(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreatePile($input: PileInput!) {\n    createPile(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation DeleteDocument($id: ID!) {\n        deleteDocument(id: $id)\n      }\n    "): (typeof documents)["\n      mutation DeleteDocument($id: ID!) {\n        deleteDocument(id: $id)\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetDocuments($id: ID!, $assetType: AssetType!) {\n    documents(assetId: $id, assetType: $assetType) {\n      id\n      uploadedBy {\n        firstName\n        lastName\n      }\n      uploadedDate\n      fileUrl\n      context\n      name\n    }\n  }\n"): (typeof documents)["\n  query GetDocuments($id: ID!, $assetType: AssetType!) {\n    documents(assetId: $id, assetType: $assetType) {\n      id\n      uploadedBy {\n        firstName\n        lastName\n      }\n      uploadedDate\n      fileUrl\n      context\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation CreateDocument($input: DocumentInput!) {\n        createDocument(input: $input) {\n          id\n          fileUrl\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateDocument($input: DocumentInput!) {\n        createDocument(input: $input) {\n          id\n          fileUrl\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation CreateNote($input: NoteInput!) {\n        createNote(input: $input) {\n          id\n          images\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateNote($input: NoteInput!) {\n        createNote(input: $input) {\n          id\n          images\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteNote($id: ID!) {\n    deleteNote(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteNote($id: ID!) {\n    deleteNote(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetPile($id: ID!) {\n        pile(id: $id) {\n          condition\n          inspectionRequired\n        }\n      }\n    "): (typeof documents)["\n      query GetPile($id: ID!) {\n        pile(id: $id) {\n          condition\n          inspectionRequired\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        fragment PileChanged on Pile {\n          condition\n          inspectionRequired\n        }\n      "): (typeof documents)["\n        fragment PileChanged on Pile {\n          condition\n          inspectionRequired\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query getCapBeam($id: ID!) {\n        capBeam(id: $id) {\n          condition\n          inspectionRequired\n        }\n      }\n    "): (typeof documents)["\n      query getCapBeam($id: ID!) {\n        capBeam(id: $id) {\n          condition\n          inspectionRequired\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        fragment CapBeamChanged on CapBeam {\n          condition\n          inspectionRequired\n        }\n      "): (typeof documents)["\n        fragment CapBeamChanged on CapBeam {\n          condition\n          inspectionRequired\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InviteUser($email: String!, $role: String!) {\n    inviteUser(email: $email, role: $role)\n  }\n"): (typeof documents)["\n  mutation InviteUser($email: String!, $role: String!) {\n    inviteUser(email: $email, role: $role)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUserRole($userId: ID!, $role: String!) {\n    updateUserRole(userId: $userId, role: $role) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserRole($userId: ID!, $role: String!) {\n    updateUserRole(userId: $userId, role: $role) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetUsers {\n        port {\n          users {\n            email\n            firstName\n            lastName\n            id\n            role\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetUsers {\n        port {\n          users {\n            email\n            firstName\n            lastName\n            id\n            role\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetAverageConditionMetrics(\n        $wharves: [String!]\n        $assetTypes: [AssetType!]\n      ) {\n        averageConditionMetric(wharves: $wharves, assetTypes: $assetTypes) {\n          date\n          condition\n        }\n      }\n    "): (typeof documents)["\n      query GetAverageConditionMetrics(\n        $wharves: [String!]\n        $assetTypes: [AssetType!]\n      ) {\n        averageConditionMetric(wharves: $wharves, assetTypes: $assetTypes) {\n          date\n          condition\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetOverviewMetric($wharves: [String!], $assetTypes: [AssetType!]) {\n        overviewMetric(wharves: $wharves, assetTypes: $assetTypes) {\n          label\n          count\n        }\n      }\n    "): (typeof documents)["\n      query GetOverviewMetric($wharves: [String!], $assetTypes: [AssetType!]) {\n        overviewMetric(wharves: $wharves, assetTypes: $assetTypes) {\n          label\n          count\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetRequiredInspectionsMetric(\n        $wharves: [String!]\n        $assetTypes: [AssetType!]\n      ) {\n        requiredInspectionsMetric(wharves: $wharves, assetTypes: $assetTypes) {\n          required\n          notRequired\n        }\n      }\n    "): (typeof documents)["\n      query GetRequiredInspectionsMetric(\n        $wharves: [String!]\n        $assetTypes: [AssetType!]\n      ) {\n        requiredInspectionsMetric(wharves: $wharves, assetTypes: $assetTypes) {\n          required\n          notRequired\n        }\n      }\n    "];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;