import type { ReactNode} from "react";
import { useState, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Box,
  ListItemAvatar,
  Avatar,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  Home as HomeIcon,
  TableChart as TableChartIcon,
  AddCircle as AddCircleIcon,
  Build as BuildIcon,
  BarChart as BarChartIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  List as ListIcon,
  MoreVert as MoreVertIcon,
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { useAuth } from "react-oidc-context";
import { authorisedRouteRoles } from "@/routes";
import { GET_PORT_QUERY } from "@/graphql";

interface NavBarProps {
  backButton?: boolean;
}

export default function NavBar({ backButton }: NavBarProps) {
  const auth = useAuth();
  const userMoreButtonRef = useRef<HTMLButtonElement>(null);
  const [userMoreMenuOpen, setUserMoreMenuOpen] = useState(false);
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { data: portData } = useQuery(GET_PORT_QUERY);
  const navigate = useNavigate();

  const actionButton = backButton ? (
    <IconButton
      size="large"
      edge="start"
      aria-label="back"
      color="inherit"
      onClick={() => navigate(-1)}
    >
      <ArrowBackIcon />
    </IconButton>
  ) : (
    <IconButton
      size="large"
      edge="start"
      aria-label="menu"
      color="inherit"
      onClick={() => setDrawerOpen(true)}
    >
      <MenuIcon />
    </IconButton>
  );

  return (
    <>
      <AppBar position="sticky" color="primary" id="top_nav">
        <Toolbar>
          {auth.isAuthenticated && actionButton}
          <Typography variant="h6" marginLeft={2}>
            AssetSide
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box sx={{ width: 256 }}>
          <List>
            <ListItem
              secondaryAction={
                <IconButton
                  onClick={() => setUserMoreMenuOpen(true)}
                  ref={userMoreButtonRef}
                >
                  <MoreVertIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>{auth?.user?.profile.given_name?.[0]}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={auth?.user?.profile.name}
                secondary={portData?.port.name}
              />
            </ListItem>
            <Menu
              open={userMoreMenuOpen}
              anchorEl={userMoreButtonRef.current}
              onClose={() => setUserMoreMenuOpen(false)}
            >
              <MenuItem onClick={() => auth.signoutPopup()}>Sign out</MenuItem>
            </Menu>
          </List>
          <Divider />
          <List>
            {navigationListItems.map(({ title, route, icon }) => {
              if (
                !hasUserAccessToRoute(
                  route,
                  auth.user?.profile.realm_access.roles ?? [],
                )
              ) {
                return null;
              }

              return (
                <ListItem disablePadding key={title}>
                  <ListItemButton
                    to={route}
                    component={Link}
                    style={{ color: "inherit" }}
                    selected={location.pathname === route}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={title} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Drawer>
    </>
  );
}

const navigationListItems: NavigationListItem[] = [
  {
    title: "Home",
    route: "/home",
    icon: <HomeIcon />,
  },
  {
    title: "Assets Table",
    route: "/assets_table",
    icon: <TableChartIcon />,
  },
  {
    title: "Add Assets",
    route: "/add_assets",
    icon: <AddCircleIcon />,
  },
  {
    title: "Configuration",
    route: "/configuration",
    icon: <BuildIcon />,
  },
  {
    title: "Metrics",
    route: "/metrics",
    icon: <BarChartIcon />,
  },
  {
    title: "Manage Access",
    route: "/manage_access",
    icon: <AdminPanelSettingsIcon />,
  },
  {
    title: "Batch Entry",
    route: "/batch_entry",
    icon: <ListIcon />,
  },
];

interface NavigationListItem {
  title: string;
  route: string;
  icon: ReactNode;
}

function hasUserAccessToRoute(route: string, userRoles: string[]) {
  return userRoles.some((role) => authorisedRouteRoles[route].has(role));
}
